import { ReactNode } from 'react';
import styled from 'styled-components';

export interface InfoListItemProps {
  icon?: string | ReactNode;
  title?: string;
  body?: string;
  variant?: 'DEFAULT' | 'LARGE';
  className?: string;
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Icon = styled.div<{ variant: 'DEFAULT' | 'LARGE' }>`
  margin-right: ${({ theme }) => theme.spacing.M16};
  ${({ variant }) => variant === 'LARGE' && 'width: 40px; height: 40px;'}
  display: flex;
  align-items: center;
`;

const Title = styled.div<{ variant: 'DEFAULT' | 'LARGE' }>`
  ${({ theme, variant }) =>
    variant === 'DEFAULT' ? theme.fontSize.B14 : theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  font-weight: ${({ theme, variant }) =>
    variant === 'DEFAULT' ? theme.fontWeight.regular : theme.fontWeight.bold};
`;

const Text = styled.p<{ variant: 'DEFAULT' | 'LARGE' }>`
  ${({ theme }) => theme.fontSize.B12};
  color: ${({ theme, variant }) =>
    variant === 'DEFAULT' ? theme.colors.GREY_DARK : theme.colors.GREY_DARK};
  margin-top: ${({ theme }) => theme.spacing.S4};
`;

function InfoListItem({
  icon,
  title,
  body,
  variant = 'DEFAULT',
  className,
}: InfoListItemProps) {
  return (
    <Container className={className}>
      {icon && (
        <Icon variant={variant}>
          {typeof icon === 'string' ? <img src={icon} /> : icon}
        </Icon>
      )}
      <div>
        <Title variant={variant}>{title}</Title>
        {body && <Text variant={variant}>{body}</Text>}
      </div>
    </Container>
  );
}

export { InfoListItem };
