import * as Styled from 'components/HomePage/styles/HomePage.styled';
import * as HOMEPAGE from 'components/HomePage/helpers/constants';
import type { LastSearchProps } from 'features/landing/LastSearch/LastSearch.typed';

const LastSearch = (props: LastSearchProps) => {
  const { lastSearchData } = props;
  return (
    <Styled.SLastSearchCard
      lastSearchData={lastSearchData}
      isDisabled={!!!lastSearchData}
      lastSearchDataTrackingValue={HOMEPAGE.LAST_SEARCH_DATA_TRACKING_VALUE}
    />
  );
};

export { LastSearch };
