import { useState, useCallback, useEffect } from 'react';
import type { ReactNode } from 'react';
import Modal, { Props } from 'react-modal';
import styled, {
  createGlobalStyle,
  FlattenSimpleInterpolation,
  css,
} from 'styled-components';
import { CloseOutlineIc } from '@dsch/dd-icons';

import { media } from 'helpers/breakpoints';
import { disableScroll, enableScroll } from 'helpers/scroll';
import { withOpacity } from 'helpers/Theme/Theme';

interface ModalProps extends Props {
  children?: ReactNode;
  askToClose: () => void;
  testId?: string;
  fixedButtonGroup?: boolean;
  variant?: 'DEFAULT' | 'WIDE' | 'LONG';
  showCloseIcon?: boolean;
  contentCSS?: FlattenSimpleInterpolation;
  closeButtonCSS?: FlattenSimpleInterpolation;
}

// http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement('#__next');

interface IStyledModalGlobal {
  isFullscreen: boolean;
  variant: string;
}

interface IModalWrapper {
  isFullscreen: boolean;
  fixedButtonGroup: boolean;
  variant: string;
  className: string;
}

interface IModalContent {
  cssStyles?: FlattenSimpleInterpolation;
}

const WideAndLongCSS = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const StyledModalGlobal = createGlobalStyle<IStyledModalGlobal>`
  .ReactModal__Overlay {
    background-color: ${({ theme }) =>
      withOpacity(theme.colors.GREY_DARKER, 0.8)};
    
    /* TODO: Once on a page work with design to define spacing for mobile vs desktop */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9950;
    display: flex;
    justify-content: center;
    align-items: center;
    // Overrides for when fullscreen:
    ${({ isFullscreen }) =>
      isFullscreen
        ? `
        padding-top: 0;
        max-height: 100%;
        `
        : ''}
  }

  .ReactModal__Content {
    outline: none;
    ${({ variant }) =>
      variant === 'LONG' &&
      `      
      ${WideAndLongCSS}

      ${media.medium}{
        width: auto;
        height: auto;
        max-height: 100vh;
        display: block;
        overflow-y: auto;
        }
      }`};

    ${({ variant }) =>
      variant === 'WIDE' &&
      `
      ${WideAndLongCSS}

      ${media.medium}{
        width: auto;
        height: auto;
        max-height: 100vh;
        display: block;
        overflow-y: auto;
        }
      }`};

    > * {
      max-height: ${({ theme }) => theme.windowInnerHeight};
      overflow: auto;

      ${({ variant }) => variant === 'WIDE' && `height: 100%;`};

      ${({ theme, variant }) =>
        variant === 'WIDE' &&
        `${media.medium}{
              max-height: ${theme.windowInnerHeight}};
              
          }   
      `};
    }
  }
`;

const Close = styled.span<{
  variant: string;
  cssStyles?: FlattenSimpleInterpolation;
}>`
  top: ${({ theme }) => theme.spacing.M24};
  right: ${({ theme }) => theme.spacing.M24};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  position: absolute;
  padding: 0;
  cursor: pointer;

  ${({ theme, variant }) =>
    variant === 'WIDE' &&
    `
     ${media.medium} {
      top: ${theme.spacing.L48};
      right: ${theme.spacing.L48};
  }`}

  ${({ cssStyles }) => cssStyles}
`;

export const ModalButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  button:first-child,
  a:first-child,
  p:first-child {
    flex: 1 100%;
    margin-right: ${({ theme }) => theme.spacing.S8};
  }
`;

const ModalWrapper = styled.div<IModalWrapper>`
  position: relative;
  overflow: auto;
  // If you change this then make sure to update accountForPadding
  padding: ${({ theme, fixedButtonGroup }) =>
    !fixedButtonGroup
      ? theme.spacing.M24
      : `${theme.spacing.M24} ${theme.spacing.M24} ${theme.spacing.L48} ${theme.spacing.M24}`};
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-shadow: ${({ theme }) => theme.getOuterShadow(theme.colors.BLACK)};
  width: ${({ variant }) => (variant === 'DEFAULT' ? '320px' : '100%')};
  border-radius: ${({ variant, theme }) =>
    variant === 'DEFAULT' ? theme.borderRadius.large : 0};
  margin: 0 auto;
  flex: ${({ className }) => (className === '1' ? 'unset' : '1')};
  -webkit-overflow-scrolling: touch;

  ${({ variant }) => variant !== 'DEFAULT' && 'max-height: 100vh;'}

  ${({ variant, fixedButtonGroup, theme }) =>
    variant === 'WIDE' &&
    `
      ${media.medium} {
        padding: ${theme.spacing.L48} ${theme.spacing.L48}  ${
      fixedButtonGroup ? 0 : theme.spacing.L48
    } ${theme.spacing.L48} ;
        border-radius: ${theme.borderRadius.large} ;
      }`};

  ${({ variant, fixedButtonGroup, theme }) =>
    variant === 'LONG' &&
    `
      ${media.medium} {
        padding: ${theme.spacing.M24} ${theme.spacing.M24} ${
      fixedButtonGroup ? 0 : theme.spacing.L48
    } ${theme.spacing.M24};
        border-radius: ${theme.borderRadius.large};
      }`};

  ${({ isFullscreen }) =>
    isFullscreen
      ? `
      border-radius: 0;
      max-height: 100%;`
      : ''}
`;

const ModalContent = styled.div<IModalContent>`
  ${({ cssStyles }) => cssStyles}
`;

function BaseModal({
  className = '',
  isOpen,
  askToClose,
  children,
  testId = '',
  fixedButtonGroup = false,
  variant = 'DEFAULT',
  showCloseIcon = true,
  contentCSS,
  closeButtonCSS,
  closeTimeoutMS,
  ...props
}: ModalProps) {
  // first number matches padding above
  const accountForPadding = 24 * 2;
  const marginForError = 10;
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  useEffect(() => {
    isOpen && disableScroll();
    return () => {
      enableScroll();
    };
  }, [isOpen]);

  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const measuredRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const modalHeight =
        node.getBoundingClientRect().height +
        accountForPadding -
        marginForError;
      if (modalHeight > window.innerHeight) {
        setIsFullscreen(true);
      }
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={askToClose}
      {...props}
      className="base-modal"
      overlayClassName="base-modal-overlay"
      closeTimeoutMS={closeTimeoutMS}
    >
      <ModalWrapper
        isFullscreen={isFullscreen}
        fixedButtonGroup={fixedButtonGroup}
        variant={variant}
        className={`${className}`}
      >
        <ModalContent
          data-testid={testId}
          ref={measuredRef}
          cssStyles={contentCSS}
        >
          <StyledModalGlobal isFullscreen={isFullscreen} variant={variant} />
          {showCloseIcon && (
            <Close
              onClick={askToClose}
              data-testid="modal-close"
              variant={variant}
              cssStyles={closeButtonCSS}
            >
              <CloseOutlineIc width="16" height="16" />
            </Close>
          )}
          {children}
        </ModalContent>
      </ModalWrapper>
    </Modal>
  );
}

export interface ModalRequiredProps extends ModalProps {}

export { BaseModal };
