import { useBrowserStorage } from 'hooks/UseBrowserStorage';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';

import { useAuthOptions } from 'hooks/useAuthOptions';
import { useHomePageValuationLookup } from 'features/landing/HomePageValuationLookup/HomePageValuationLookup.hook';

import type { ValuationLookupFormValues } from 'features/landing/HomePageValuationLookup/HomePageValuationLookup.typed';
import { HOMEPAGE_VALUATION_LOOKUP } from 'features/landing/HomePageValuationLookup/HomePageValuationLookup.typed';

import { FORM_VALUES } from 'features/landing/HomePageValuationLookup/HomePageValuationLookup.typed';

const useValuationLookupForm = () => {
  const { accessToken } = useAuthOptions();
  const { batch, change } = useForm();
  const { getLocalStorageItem } = useBrowserStorage();
  const { isHomePageValuationModalQueryPresent } = useHomePageValuationLookup({
    accessToken,
  });

  const homepageValuationFormData: ValuationLookupFormValues =
    getLocalStorageItem(HOMEPAGE_VALUATION_LOOKUP.KEY);

  useEffect(() => {
    if (isHomePageValuationModalQueryPresent && homepageValuationFormData) {
      const { registration, mileage, mileageType } = homepageValuationFormData;

      batch(() => {
        change(FORM_VALUES.REGISTRATION, registration);
        change(FORM_VALUES.MILEAGE, mileage);
        change(FORM_VALUES.MILEAGE_TYPE, mileageType);
      });
    }
  }, [isHomePageValuationModalQueryPresent]);
};

export { useValuationLookupForm };
