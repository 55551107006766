import styled from 'styled-components';

import { InfoTitle } from 'components/Toolkit/InfoTitle/InfoTitle';
import { TimeOutlineIc } from '@dsch/dd-icons';

import type { TLastSearch } from 'components/PageWrappers/SharedComponents/LastSearchCard/types';

interface ILastSearchesCard {
  lastSearchCallback?: () => void;
  lastSearchData?: TLastSearch;
  className?: string;
  lastSearchDataTrackingValue?: string;
}

const Container = styled.div<{ isDisabled: boolean }>`
  background: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.GREY_LIGHTER : theme.colors.WHITE};

  &:hover {
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  }
`;

const STimeOutlineIc = styled(TimeOutlineIc)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.GREY_LIGHT};
`;

const SLink = styled.a<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const SLastSearchTerms = styled.span`
  @supports (-webkit-line-clamp: 1) {
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  overflow: hidden;
`;

function LastSearchCard({
  lastSearchCallback,
  lastSearchData,
  className,
  lastSearchDataTrackingValue,
}: ILastSearchesCard) {
  return (
    <SLink
      href={lastSearchData?.searchLink}
      onClick={lastSearchCallback}
      disabled={!!!lastSearchData}
      aria-disabled={!!!lastSearchData}
      data-tracking={lastSearchDataTrackingValue}
    >
      <Container className={className} isDisabled={!!!lastSearchData}>
        <InfoTitle
          title="My Last Search"
          description={
            lastSearchData?.terms ? (
              <SLastSearchTerms>{lastSearchData?.terms}</SLastSearchTerms>
            ) : (
              'You currently do not have a last search'
            )
          }
          icon={<STimeOutlineIc height="16" width="16" />}
          variant="SMALL"
          disabled={!!!lastSearchData}
        />
      </Container>
    </SLink>
  );
}

export { LastSearchCard };
