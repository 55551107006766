import { useRouter } from 'next/router';

function useQueryParams() {
  const { push, query } = useRouter();

  const addQueries = (additionalParams: { [key: string]: string }) => {
    push({ query: { ...query, ...additionalParams } }, undefined, {
      shallow: true,
    });
  };

  const removeQueries = (queryKeys: string[]) => {
    const filteredObject = Object.fromEntries(
      Object.entries(query).filter(([key]) => !queryKeys.includes(key)),
    );
    push({ query: filteredObject }, undefined, {
      shallow: true,
    });
  };

  const checkIfQueryIsPresent = (queryKey: string, queryValue: string) => {
    return query[queryKey] === queryValue;
  };

  return {
    addQueries,
    removeQueries,
    checkIfQueryIsPresent,
  };
}

export { useQueryParams };
