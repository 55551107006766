import { Button } from 'components/Toolkit/Button';

import { ValuationToolFields } from 'components/CarValuationPage/components/ValuationTool/ValuationToolFields';
import * as Styled from 'features/landing/HomePageValuationLookup/HomePageValuationLookup.styled';

import { CDN_STATIC_ASSETS } from 'utils';
import { useValuationLookupForm } from 'features/landing/HomePageValuationLookup/ValuationLookupForm/ValuationLookupForm.hook';

const ValuationLookupForm = () => {
  useValuationLookupForm();

  return (
    <div className="row small-collapse medium-uncollapse">
      <div className="columns small-12">
        <Styled.ValuationLookupWrapper>
          <div>
            <Styled.PartnershipIntro>
              How much is my car worth?
            </Styled.PartnershipIntro>
            <Styled.Partnership>
              <p>In partnership with</p>
              <img
                src={`${CDN_STATIC_ASSETS}/images/thirdparty/AllianzSecondary.svg`}
              />
            </Styled.Partnership>
          </div>

          <Styled.ValuationToolFieldContainer>
            <ValuationToolFields cdnUrl={CDN_STATIC_ASSETS} />
            <Styled.SubmitButtonWrapper>
              <Button type="submit" inline ofType="TERTIARY">
                Get my free valuation
              </Button>
            </Styled.SubmitButtonWrapper>
          </Styled.ValuationToolFieldContainer>
        </Styled.ValuationLookupWrapper>
      </div>
    </div>
  );
};

export { ValuationLookupForm };
