import { DFPSlotsProvider } from '@dsch/react-dfp';

import type {
  DataTargetingProps,
  ISeoLinks,
  DashLink,
  LifestyleLinks,
} from 'types';

import type { AdCount } from 'api/types/adviewApiTypes';

import { useLastSearch } from 'components/PageWrappers/SharedComponents/LastSearchCard/hooks/useLastSearch';
import type { SiteFocus } from 'components/HomePage/types';
import {
  HomePageData,
  useHomePage,
} from 'components/HomePage/hooks/useHomePage';
import * as Styled from 'components/HomePage/styles/HomePage.styled';
import { Hero } from 'features/landing/Hero/Hero';
import { TopAdUnit } from 'features/landing/TopAdUnit/TopAdUnit';
import { LastSearch } from 'features/landing/LastSearch/LastSearch';
import { Sections } from 'features/landing/Sections/Sections';
import { FeaturedAds } from 'features/landing/FeaturedAds/FeaturedAds';
import { RecentAds } from 'features/landing/RecentAds/RecentAds';
import { GoFurther } from 'features/landing/GoFurther/GoFurther';
import { PopularSearches } from 'features/landing/PopularSearches/PopularSearches';
import { HomePageValuationLookup } from 'features/landing/HomePageValuationLookup/HomePageValuationLookup';
import { ValuationModal } from 'components/HomePage/components/ValuationModal/ValuationModal';
import { useHomePageContext } from 'contexts/HomePageContext';
import type { ISearchPageFilter } from 'api/types/searchPageApiTypes';
import { getDFPLazyLoadConfig } from 'components/Toolkit/DFP/DFP';
import { LifeStyleCarousel } from 'components/HomePage/components/MotorSearch/LifeStyleCarousel/LifeStyleCarousel';

type InitHomePage = {
  siteFocus: SiteFocus;
  homePageData: HomePageData;
};

interface HomePageProps {
  cdnUrl: string;
  init: InitHomePage;
  dataTargetingProps: DataTargetingProps;
  seoLinks: ISeoLinks;
  domain: string;
  dfpNetworkId: string;
  dashLinks: DashLink[];
  lifestyleLinks: LifestyleLinks[];
  unreadInAppMessage?: string;
  setVertical: (tab: SiteFocus) => void;
  adCount: AdCount;
  motorSearchFilters: ISearchPageFilter[];
}

function HomePage(props: HomePageProps) {
  const {
    cdnUrl,
    init,
    seoLinks,
    domain,
    dfpNetworkId,
    dashLinks,
    lifestyleLinks,
    setVertical,
    adCount,
    dataTargetingProps,
    motorSearchFilters,
  } = props;
  const { homePageData, siteFocus, setSiteFocus } = useHomePage({
    init,
    setVertical,
    domain,
    dataTargetingProps,
  });

  const { lastSearchData, setLastSearch } = useLastSearch();

  const { isValuationModalOpen } = useHomePageContext();

  const lifestyleLinksValues = homePageData.lifestyleLinks || lifestyleLinks;

  return (
    <Styled.HomePageWrapper>
      {/* Hero Section */}
      <Hero
        cdnUrl={cdnUrl}
        domain={domain}
        adCount={adCount}
        siteFocus={siteFocus}
        setSiteFocus={setSiteFocus}
        lastSearchData={lastSearchData}
        setLastSearch={setLastSearch}
        motorSearchFilters={motorSearchFilters}
      />
      <DFPSlotsProvider dfpNetworkId={dfpNetworkId} {...getDFPLazyLoadConfig()}>
        <Styled.FlexReordering>
          {/* Top Ad Unit */}
          <TopAdUnit siteFocus={siteFocus} />
          <Styled.WhiteSection>
            <Styled.SectionBrowserHeight
              focus={siteFocus}
              hasSections={Boolean(homePageData.sections.length)}
            >
              <div className="row small-collapse medium-uncollapse set-width-100">
                <div className="columns small-12" data-testid="h1-homepage">
                  <Styled.Container>
                    {/* Last Search */}
                    <LastSearch lastSearchData={lastSearchData} />

                    {/* Life Style Carousel */}
                    {siteFocus === 'motor' &&
                      lifestyleLinksValues &&
                      lifestyleLinksValues.length > 0 && (
                        <LifeStyleCarousel
                          lifestyleLinks={lifestyleLinksValues}
                        />
                      )}

                    {/* Sections */}
                    <Sections
                      h1={homePageData.h1}
                      sections={homePageData.sections}
                      siteFocus={siteFocus}
                      dashLinks={dashLinks}
                    />
                  </Styled.Container>
                </div>
              </div>
            </Styled.SectionBrowserHeight>

            <HomePageValuationLookup />
          </Styled.WhiteSection>
        </Styled.FlexReordering>
        {/* Featured Ads */}
        {homePageData.featuredAds && (
          <FeaturedAds
            cdnUrl={cdnUrl}
            domain={domain}
            featuredAds={homePageData.featuredAds}
          />
        )}
        {/* Recent Ads */}
        {homePageData.recentAds?.length > 0 && (
          <RecentAds
            domain={domain}
            recentAds={homePageData.recentAds}
            recentAdsUrl={homePageData.recentAdsUrl}
          />
        )}
        {/* Go Further */}
        <GoFurther dashLinks={dashLinks} />
      </DFPSlotsProvider>
      {/* Popular Searches */}
      <PopularSearches seoLinks={seoLinks} />
      {isValuationModalOpen && <ValuationModal />}
    </Styled.HomePageWrapper>
  );
}

export { HomePage };
