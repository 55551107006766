import { LastSearchCard } from 'components/PageWrappers/SharedComponents/LastSearchCard/LastSearchCard';
import { TabSelector } from 'components/Toolkit/TabSelector/TabSelector';
import { media } from 'helpers/breakpoints';
import styled from 'styled-components';
import { ThemeTypes } from 'types/Theme.namespace';

export const HomePageWrapper = styled.main``;

export const WhiteSection = styled.div`
  background-color: ${({ theme }) => theme.colors.WHITE};
`;

export const LifeStyleSection = styled.div`
  margin: ${({ theme }) => theme.spacing.M24} 0;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;

export const Heading = styled.h2`
  ${({ theme }) => theme.fontSize.L18};
`;

type THomePageUnit = { backgroundColor: ThemeTypes.Colors };
export const HomePageUnit = styled.div<THomePageUnit>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.colors[backgroundColor] : 'transparent'};
  padding: ${({ theme }) => theme.spacing.S8} 0;

  ${media.large} {
    padding: ${({ theme }) => theme.spacing.M16} 0 0 0;
  }

  ${media.smallOnly} {
    order: 1;
  }
`;

export const UnitContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  ${media.large} {
    padding: 0 ${({ theme }) => theme.spacing.M16};
  }
`;

export const FlexReordering = styled.div`
  display: flex;
  flex-direction: column;

  .set-width-100 {
    width: 100%;
  }
`;

export const Container = styled.div<{ isCarousel?: boolean }>`
  padding: ${({ theme, isCarousel }) =>
    isCarousel ? `${theme.spacing.M16} 0` : theme.spacing.M16};
  ${media.medium} {
    padding: ${({ theme }) => theme.spacing.M24} 0;
  }

  ${media.smallOnly} {
    ${({ isCarousel, theme }) =>
      isCarousel
        ? `
      ${Heading} {
        margin-left: ${theme.spacing.M16}
      }
    `
        : ''}
  }
`;

export const HeroContainer = styled.div`
  display: block;
  max-width: 288px;
  margin: 0 auto;

  @media (min-width: 640px) {
    max-width: 592px;
  }
`;

export const STabSelector = styled(TabSelector)`
  margin: 0 auto ${({ theme }) => theme.spacing.M24};
  max-width: 360px;
`;

interface ISectionBrowserHeight {
  focus: string;
  hasSections: boolean;
}
export const SectionBrowserHeight = styled.div<ISectionBrowserHeight>`
  min-height: ${({ focus, hasSections }) =>
    hasSections ? (focus === 'motor' ? '480px' : '380px') : '50px'};
`;

export const SLinkPadding = styled.div`
  ${media.smallOnly} {
    padding: ${({ theme }) => `0 ${theme.spacing.M16}`};
  }
`;

export const SLastSearchCard = styled(LastSearchCard)<{ isDisabled: boolean }>`
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.S12}`};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  box-shadow: ${({ isDisabled, theme }) =>
    isDisabled ? 'none' : theme.shadow.CONTAINER};
  border-radius: ${({ theme }) => theme.borderRadius.default};

  ${media.large} {
    display: none;
  }
`;
