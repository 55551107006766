import NextLink from 'next/link';

import type { WithLinkProps } from 'components/ToolkitV2/CardV2/enhancements/WithLink/WithLink.typed';

import * as Styled from 'components/ToolkitV2/CardV2/enhancements/WithLink/WithLink.styled';

import { accessibleOnClick } from 'utils/Accessibility';

function WithLink(args: WithLinkProps) {
  const { children, href, isFakeLink, handleClick, className, dataTracking } =
    args;

  const clickHandler = handleClick
    ? accessibleOnClick(() => handleClick(), 'link')
    : null;

  const isLink = href && !isFakeLink;

  return isLink ? (
    <NextLink
      href={href}
      passHref
      className={className}
      data-tracking={dataTracking}
    >
      {children}
    </NextLink>
  ) : (
    <Styled.Container
      {...clickHandler}
      className={className}
      data-tracking={dataTracking}
    >
      {children}
    </Styled.Container>
  );
}

export { WithLink };
