import { Base } from 'domains/Base';
import { MILEAGE_UNIT } from 'types';

export type VehicleRenewal = {
  displayText: string;
  value: string;
};

export enum STRIPE_STATUS {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

type Trader = {
  name: string;
  address?: string;
  vat?: string;
} | null;

type Stripe = {
  status: STRIPE_STATUS;
  clientId: string | null;
  accountId: string | null;
  state: string | null;
};

type CommunicationSettingsOptions = {
  email: boolean;
  notification: boolean;
  none: boolean;
};

type Consent = {
  acceptedTermsOfUse: boolean;
  productRecommendationsOption: boolean;
  communicationSettingsOptions: CommunicationSettingsOptions;
};

export type Vehicle = {
  registrationNumber: string | null;
  isNotVehicleOwner: string;
  mileage: string | null;
  mileageUnit: MILEAGE_UNIT | null;
  insuranceRenewal?: string | null;
  insuranceTargetable?: boolean;
};

export type NotificationPreferencesCategories = {
  newsAndOffers: boolean;
  savedSearch: boolean;
  sellingTips: boolean;
  recommendations: boolean;
  savedAds: boolean;
};

export interface User extends Base {
  name: string;
  phone: string;
  county: string;
  town: string;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  trader?: Trader;
  email?: string;
  stripe?: Stripe;
  // This attribute is DoneDeal specific - will need reconsideration for monorepo
  vehicle?: Vehicle | null;
  consent?: Consent;
}

export const createUser = (
  id: string,
  name: string,
  email: string,
  phone: string,
  county: string,
  town: string,
  isPhoneVerified: boolean,
  isEmailVerified: boolean,
  trader?: Trader,
  stripe?: Stripe,
  consent?: Consent,
  vehicle?: Vehicle,
): User => {
  return {
    id,
    name,
    email,
    phone,
    county,
    town,
    isPhoneVerified,
    isEmailVerified,
    trader,
    stripe,
    consent,
    vehicle,
  };
};

export const isUserTrader = (user: User) => Boolean(user.trader);
export const isStripeConnected = (stripeStatus: STRIPE_STATUS) =>
  stripeStatus === STRIPE_STATUS.CONNECTED;
export const isStripeAvailable = (stripeStatus: STRIPE_STATUS) =>
  stripeStatus !== STRIPE_STATUS.NOT_AVAILABLE;
export const isMinimumVerified = (user: User) =>
  Boolean(user.isEmailVerified || user.isPhoneVerified);
