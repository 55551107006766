export function filterKeyEnter(handler: any) {
  return (e: any) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault();
      handler(e);
    }
  };
}

export function accessibleOnClick(
  handler: () => void,
  role = 'button',
  tabIndex = 0,
) {
  return {
    role,
    tabIndex: tabIndex,
    onKeyDown: filterKeyEnter(handler),
    onClick: handler,
  };
}
